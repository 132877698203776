import { Box, Card, CardHeader } from '@mui/material';
import { format } from 'date-fns';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { BaseOptionChart } from '../../../../components/chart';
import { ClassifiedTobaccoCostChartFilter } from './components';
import { useData, useFormFilter } from './hooks';

// -----------------------------------------------------------------

const ClassifiedTobaccoCostChart = ({ title, subheader }) => {
  const { t } = useTranslation();
  // chart data
  const baseOptions = BaseOptionChart();

  const { isSynchronized, selectedTobaccos, methods } = useFormFilter();

  const { list, isTobaccosListLoading, tobaccosList } = useData({ tobaccos: selectedTobaccos, isSynchronized });

  const [chartData, setChartData] = useState({
    series: [],
    options: {},
  });

  useEffect(() => {
    // Get a list of all unique dates in the list
    const allDates = _.uniq(list.map((item) => format(new Date(item.timestamp), 'P')));

    // Use lodash to transform List into Series
    const series = _.chain(list)
      .groupBy((item) => item.tobacco.code) // Group by tobacco code
      .map((group, code) => ({
        name: code,
        data: _.map(allDates, (date) => {
          const dayItems = _.filter(group, (item) => format(new Date(item.timestamp), 'P') === date);
          const dayItemsCostPerUnitAvg = Number((_.meanBy(dayItems, 'costPerUnit') || 0).toFixed(4));

          return {
            x: new Date(date),
            y: dayItemsCostPerUnitAvg,
          };
        }),
      }))
      .map((tobaccoData) => ({
        ...tobaccoData,
        data: tobaccoData.data.map((dayData, index) => {
          if (dayData.y === 0) {
            // If current day's y is 0 and we have a previous day, set y to the previous day's y
            dayData.y = index > 0 ? tobaccoData.data[index - 1].y : null;
          }
          return dayData;
        }),
      }))
      .value();

    // Create the chart options
    const options = {
      plotOptions: { bar: { columnWidth: '16%' } },
      fill: { type: series.map((i) => i.fill) },
      series,
      xaxis: {
        type: 'datetime',
        labels: {
          formatter: (date) => {
            if (date) return t('date.short', { date: new Date(date) });
            return date;
          },
        },
      },
      yaxis: {
        title: {
          text: t('label.costPerLb'),
        },
      },
      tooltip: {
        shared: true,
        intersect: false,
      },
    };

    setChartData({
      series,
      options: _.merge(baseOptions, options),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, list]);

  return (
    <Card>
      <CardHeader title={title} subheader={subheader} />

      <ClassifiedTobaccoCostChartFilter
        methods={methods}
        tobaccosList={tobaccosList}
        selectedListCount={selectedTobaccos.length}
        isLoading={isTobaccosListLoading}
      />

      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart
          type="line"
          key="classifiedTobaccoCostChart"
          series={chartData.series}
          options={chartData.options}
          height={500}
        />
      </Box>
    </Card>
  );
};

ClassifiedTobaccoCostChart.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
};

export default ClassifiedTobaccoCostChart;
