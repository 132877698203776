import { createSlice } from '@reduxjs/toolkit';

import { addExtraReducer } from '../../utils/addExtraReducer';
// initial state
import initialState from './initialState';
// thunks
import {
  GetTobaccoSales,
  DeleteTobaccoSaleContainer,
  UpdateTobaccoSale,
  CreateFactoryOption,
  GetTobaccoSaleOptions,
  CancelTobaccoSale
} from './thunks';

import {
  GetTobaccoSalesReducer,
  DeleteTobaccoSaleContainerReducer,
  UpdateTobaccoSaleReducer,
  CreateFactoryOptionReducer,
  GetTobaccoSaleOptionsReducer,
  ResetTobaccoSalesStateReducer,
  CancelTobaccoSaleReducer
} from './reducers';

// ----------------------------------------------------------------------------

// slice
export const slice = createSlice({
  name: 'tobaccoSales',
  initialState,
  reducers: {
    ResetTobaccoSalesState: ResetTobaccoSalesStateReducer,
  },
  extraReducers: (builder) => {
    // Get
    addExtraReducer(builder, GetTobaccoSales, GetTobaccoSalesReducer);
    // Delete
    addExtraReducer(builder, DeleteTobaccoSaleContainer, DeleteTobaccoSaleContainerReducer);
    // Update
    addExtraReducer(builder, UpdateTobaccoSale, UpdateTobaccoSaleReducer);
    // GetTobaccoSaleOptions
    addExtraReducer(builder, GetTobaccoSaleOptions, GetTobaccoSaleOptionsReducer);
    // CreateFactoryOption
    addExtraReducer(builder, CreateFactoryOption, CreateFactoryOptionReducer);
    // CancelTobaccoSale
    addExtraReducer(builder, CancelTobaccoSale, CancelTobaccoSaleReducer);
  },
});

export default slice.reducer;
