import initialState from './initialState';

export const CreatePurchaseOrderReducer = {
  pending: (state) => ({
    ...state,
    loading: 'pending',
  }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    return {
      ...state,
      list: [...state.list, data],
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const GetPurchaseOrderByIdReducer = {
  pending: (state) => ({
    ...state,
    selectedItemLoading: 'pending',
  }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    return {
      ...state,
      selectedItem: data,
      list: state.list.map((item) => {
        if (item._id === data._id) return data;

        return item;
      }),
      selectedItemLoading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    selectedItemLoading: 'failed',
  }),
};

export const GetPurchaseOrdersReducer = {
  pending: (state) => ({
    ...state,
    loading: 'pending',
  }),
  fulfilled: (state, action) => ({
    ...state,
    selectedItem: action.payload.data.data.find((item) => item._id === state.selectedItem?._id) || state.selectedItem,
    list: action.payload.data.data,
    totalRecords: action.payload.data.totalRecords,
    loading: 'succeeded',
  }),
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const UpdatePurchaseOrderReducer = {
  pending: (state) => ({
    ...state,
    loading: 'pending',
  }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    return {
      ...state,
      selectedItem: data._id === state.selectedItem?._id ? data : state.selectedItem,
      list: state.list.map((item) => {
        if (item._id === data._id) return data;

        return item;
      }),
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const GetPurchaseOrderRawMaterialsReducer = {
  pending: (state) => ({
    ...state,
    rawMaterialsLoading: 'pending',
  }),
  fulfilled: (state, action) => ({
    ...state,
    rawMaterials: action.payload.data?.list || [],
    rawMaterialsLoading: 'succeeded',
  }),
  rejected: (state) => ({
    ...state,
    rawMaterialsLoading: 'succeeded',
  }),
};

export const GetPurchaseOrderSuppliersReducer = {
  pending: (state) => ({
    ...state,
    suppliersLoading: 'pending',
  }),
  fulfilled: (state, action) => ({
    ...state,
    suppliers: action.payload.data || [],
    suppliersLoading: 'succeeded',
  }),
  rejected: (state) => ({
    ...state,
    suppliersLoading: 'succeeded',
  }),
};

export const CreatePurchaseOrderSupplierReducer = {
  pending: (state) => ({
    ...state,
    suppliersLoading: 'pending',
  }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    return {
      ...state,
      suppliers: [...state.suppliers, data],
      suppliersLoading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    suppliersLoading: 'failed',
  }),
};

export const MarkPurchaseOrderDoneReducer = {
  pending: (state) => ({
    ...state,
    loading: 'pending',
  }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    return {
      ...state,
      selectedItem: data._id === state.selectedItem?._id ? data : state.selectedItem,
      list: state.list.map((item) => {
        if (item._id === data._id) {
          return {
            ...item,
            status: data.status,
          };
        }

        return item;
      }),
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const GeneratePurchaseOrderReportReducer = {
  pending: (state) => ({
    ...state,
    loading: 'pending',
    generatedOrder: {
      ...state.generatedOrder,
      loading: 'pending',
    }
  }),
  fulfilled: (state) => ({
    ...state,
    loading: 'succeeded',
    generatedOrder: {
      ...state.generatedOrder,
      loading: 'succeeded',
    }
  }),
  rejected: (state) => ({
    ...state,
    loading: 'failed',
    generatedOrder: {
      ...state.generatedOrder,
      loading: 'failed',
    }
  }),
};

export const ClearSelectedPurchaseOrderReducer = (state) => ({
  ...state,
  selectedItem: null,
});

export const ResetPurchaseOrderStateReducer = () => initialState;
