const initialState = {
  selectedItem: null,
  list: [],
  totalRecords: 0,
  suppliers: [],
  rawMaterials: [],

  selectedItemLoading: 'idle',
  suppliersLoading: 'idle',
  rawMaterialsLoading: 'idle',

  loading: 'idle',

  generatedOrder: {
    data: [],
    loading: 'idle'
  }
};

export default initialState;
