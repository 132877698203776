import initialState from './initialState';

export const GetAuthenticationActivityReducer = {
  pending: (state) => ({
    ...state,
    loading: 'pending',
  }),
  fulfilled: (state, action) => {
    const { list, rowsCount } = action.payload.data;

    return {
      ...state,
      list,
      rowsCount,
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const UpdateAuthenticationActivityReducer = {
  pending: (state) => ({
    ...state,
    loading: 'pending',
    selecedItem: {
      ...state.selecedItem,
      loading: 'pending',
    },
  }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    return {
      ...state,
      list: state.list.map((authenticationActivity) => {
        if (authenticationActivity._id === data._id) return data;

        return authenticationActivity;
      }),
      loading: 'succeeded',
      selecedItem: {
        data,
        loading: 'succeeded',
      },
    };
  },
  rejected: (state) => ({
    ...state,
    loading: 'failed',
    selecedItem: {
      ...state.selecedItem,
      loading: 'failed',
    },
  }),
};

export const UpdateAuthenticationActivityStatusReducer = {
  pending: (state) => ({
    ...state,
    loading: 'pending',
  }),
  fulfilled: (state, action) => {
    const { status, id } = action.payload;

    return {
      ...state,
      list: state.list.map((authenticationActivity) => {
        if (authenticationActivity._id === id) {
          return {
            ...authenticationActivity,
            status,
          };
        }

        return authenticationActivity;
      }),
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const ClearSelectedAuthenticationActivityReducer = (state) => ({
  ...state,
  selecedItem: {
    data: null,
    loading: 'idle',
  },
});

export const GetFactoryOptionsReducer = {
  pending: (state) => ({
    ...state,
    options: {
      ...state.options,
      loading: 'pending',
    },
  }),
  fulfilled: (state, action) => {
    const { factoryConfig } = action.payload;

    return {
      ...state,
      options: {
        data: {
          factoryConfig: {
            data: factoryConfig?.data || [],
            loading: 'succeeded',
          },
        },
        loading: 'succeeded',
      },
    };
  },
  rejected: (state) => ({
    ...state,
    options: {
      ...state.options,
      loading: 'failed',
    },
  }),
};

export const ResetAuthenticationActivityStateReducer = () => initialState;
