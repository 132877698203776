import { TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import QRTooltip from '../../../components/tooltips/QRTooltip';
import { getTobaccoLabel } from '../../../utils/modelLabels';
import { TableCell } from '../table';
import ConveyorTransactionsSubitems from './ConveyorTransactionsSubitems';

const SIZE = 'xs';

export default function ConveyorTransactionsRow({
  row,
  loading,
  seeCost,
  handleOpenCorrectionModal,
  handleOpenCorrectionHistoryInfo,
  corrections,
}) {
  const firstTransaction = row?.transactions[0];
  const qrId = firstTransaction?.qrId;
  const tobacco = firstTransaction?.tobacco;

  return (
    <>
      <TableRow size={SIZE} hover key={row.tobaccoContainer} tabIndex={-1}>
        <TableCell
          align="left"
          sx={{ maxWidth: { xs: '50px', sm: '200px', md: 'auto' }, borderBottom: '2px solid black' }}
        >
          {getTobaccoLabel(tobacco)}
        </TableCell>
        <TableCell align="left" sx={{ borderBottom: '2px solid black' }}>
          <QRTooltip qr={qrId} />
        </TableCell>
        <TableCell sx={{ borderBottom: '2px solid black' }}>
          <ConveyorTransactionsSubitems
            handleOpenCorrectionModal={handleOpenCorrectionModal}
            handleOpenCorrectionHistoryInfo={handleOpenCorrectionHistoryInfo}
            list={row?.transactions}
            loading={loading}
            seeCost={seeCost}
            corrections={corrections}
          />
        </TableCell>
      </TableRow>
    </>
  );
}

ConveyorTransactionsRow.propTypes = {
  row: PropTypes.object,
  loading: PropTypes.bool,
  seeCost: PropTypes.bool,
  handleOpenCorrectionModal: PropTypes.func,
  handleOpenCorrectionHistoryInfo: PropTypes.func,
  corrections: PropTypes.array,
};
