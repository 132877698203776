// import PropTypes from 'prop-types';
import { useEffect, useState, useCallback } from 'react';

import { Stack, Typography, ToggleButton, ToggleButtonGroup, Button, Snackbar, Alert } from '@mui/material';

import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import { createPortal } from 'react-dom';
import {
  GetAuthenticationActivity,
  UpdateAuthenticationActivityStatus,
  GetFactoryOptions,
} from '../../redux/reducers/authenticationActivity/thunks';
import selectors from '../../redux/reducers/authenticationActivity/selectors';

import Page from '../../components/Page';
import FactoryConfig from '../../components/FactoryConfig';

import AuthenticationActivityTable from '../../sections/@dashboard/authenticationActivity/AuthenticationActivityTable';

import { useAuth } from '../../providers/AuthProvider';
import useLocalStorage from '../../hooks/useLocalStorage';

import { FactoryConfigType } from '../../constants/enums';
import { ResetAuthenticationActivityState } from '../../redux/reducers/authenticationActivity/actions';
import { WhiteListModal } from '../UsersWithoutFactory/components/WhiteListModal';
import { useModalForm } from './hooks';
import Iconify from '../../components/Iconify';
import { isUserWhiteListLoading } from '../../redux/reducers/userWhiteList/selectors';

// ----------------------------------------------------------------------

export default function AuthenticationActivity() {
  const { localStoragePrefix } = useAuth();
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { isOpen, handleOpenModalForm, handleCloseModalForm } = useModalForm();
  const [successModal, setSuccessModal] = useState(false);

  const isUsersWhiteListLoading = useSelector(isUserWhiteListLoading);

  const list = useSelector(selectors.list);
  const rowsCount = useSelector(selectors.rowsCount);
  const loading = useSelector(selectors.loading);

  const options = useSelector(selectors.options.data);
  const optionsIsLoading = useSelector(selectors.options.isLoading);

  const [page, setPage] = useState(0);

  const [pagination, setPagination] = useLocalStorage(`${localStoragePrefix}_AUTHENTIFICATION_ACTIVITY_PAGINATION`, {
    rowsPerPage: 50,
  });

  const handleOnPageChange = useCallback((_, page) => {
    setPage(page);
  }, []);

  const handleOnRowsPerPageChange = useCallback((event) => {
    setPagination({rowsPerPage: Number(event.target.value)});
  }, [setPagination]);

  // Filter
  const [filter, setFilter] = useLocalStorage(`${localStoragePrefix}_AUTHENTIFICATION_ACTIVITY`, {
    statuses: [],
  });

  const onChangeStatuses = (_event, value) => {
    setFilter({
      ...filter,
      statuses: value,
    });
  };

  const onGetAuthenticationActivity = () => {
    dispatch(GetAuthenticationActivity({...filter, page, rowsPerPage: pagination.rowsPerPage}));
  };

  const onGetOptions = () => {
    dispatch(GetFactoryOptions());
  };

  const onUpdateAuthenticationActivityStatus = (status, id) => {
    dispatch(UpdateAuthenticationActivityStatus({ status, id }));
  };

  const allowedIPListOption = options.factoryConfig.data.find((option) => option.name === 'AllowedIPList');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(onGetOptions, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(onGetAuthenticationActivity, [filter, page, pagination.rowsPerPage]);

  useEffect(
    () => () => {
      dispatch(ResetAuthenticationActivityState());
    },
    [dispatch]
  );

  return (
    <Page title={t('pages.authenticationActivity')}>
      <Stack
        spacing={2}
        direction={{ xs: 'column', sm: 'row' }}
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <Typography variant="h4" gutterBottom>
          {t('pages.authenticationActivity')}
        </Typography>

        <Stack
          spacing={2}
          direction={{ xs: 'column', sm: 'row' }}
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <FactoryConfig
            selectedItem={allowedIPListOption} // TODO: It should be an just a name 'AllowedIPList' and inside you should take data
            type={FactoryConfigType.CommaSeparator}
            isLoading={optionsIsLoading}
          />

          <ToggleButtonGroup color="primary" value={filter.statuses} onChange={onChangeStatuses}>
            <ToggleButton value="Pending">{t('authenticationActivityPage.status.Pending')}</ToggleButton>
            <ToggleButton value="Approved">{t('authenticationActivityPage.status.Approved')}</ToggleButton>
            <ToggleButton value="AutoApproved">{t('authenticationActivityPage.status.AutoApproved')}</ToggleButton>
            <ToggleButton value="Declined">{t('authenticationActivityPage.status.Declined')}</ToggleButton>
          </ToggleButtonGroup>
        </Stack>

        <Button
          disabled={isUsersWhiteListLoading}
          variant="contained"
          onClick={handleOpenModalForm}
          startIcon={<Iconify icon="mdi:shield-check" />}
        >
          {t('usersPage.button.whiteList')}
        </Button>
      </Stack>

      <AuthenticationActivityTable
        list={list}
        loading={loading}
        onUpdateStatus={onUpdateAuthenticationActivityStatus}
        page={page}
        rowsPerPage={pagination.rowsPerPage}
        rowsCount={rowsCount}
        onPageChange={handleOnPageChange}
        onRowsPerPageChange={handleOnRowsPerPageChange}
      />
      {createPortal(
        <WhiteListModal setSuccess={setSuccessModal} isOpen={isOpen} handleClose={handleCloseModalForm} />,
        document.body
      )}
      <Snackbar
        open={successModal}
        onClose={() => setSuccessModal(false)}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert severity="success" sx={{ width: '100%' }} onClose={() => setSuccessModal(false)}>
          {t('alert.success')}
        </Alert>
      </Snackbar>
    </Page>
  );
}
