import { Badge, Box, Collapse, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { alpha, styled, useTheme } from '@mui/material/styles';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
import { indicatorColors } from '../constants/enums';
import { useAuth } from '../providers/AuthProvider';
import selectors from '../redux/reducers/pageIndicators/selectors';
import { UserRoleEnum } from '../services/access';
import Iconify from './Iconify';
import ConveyorsMenu from './ConveyorsMenu';
import conveyorsMenu from '../redux/reducers/conveyorsMenu/selectors';
import useAccess from '../hooks/useAccess';

// ----------------------------------------------------------------------

const ListItemStyle = styled((props) => <ListItemButton disableGutters {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  height: 35,
  margin: 2,
  lineHeight: 1,
  position: 'relative',
  textTransform: 'capitalize',
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

// ----------------------------------------------------------------------

NavItemV2.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func,
};

function NavItemV2({ item, active }) {
  const { t } = useTranslation();
  const { role, account } = useAuth();
  const theme = useTheme();

  const { userPagePermissions } = account || {};
  const isActiveRoot = active(item.path, false);
  const { title, path, icon, info, children } = item;

  const [open, setOpen] = useState(isActiveRoot);
  const [badgeCount, setBadgeCount] = useState(0); // State for the badge count

  const indicators = useSelector(selectors.list);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
  };

  const activeSubStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
  };

  useEffect(() => {
    if (children) {
      const isActiveSub = children.some(({ path }) => active(path));

      if (isActiveSub) setOpen(true);
    }
  }, [active, children]);

  // Precompute the badge count outside of rendering
  useEffect(() => {
    if (children) {
      const totalBadgeCount = children?.reduce((total, item) => {
        const indicatorsInfo = indicators?.find((i) => i.page === item.title);
        if (indicatorsInfo) {
          return total + indicatorsInfo.indicators.reduce((sum, indicator) => sum + (indicator.quantity || 0), 0);
        }
        return total;
      }, 0);
      setBadgeCount(totalBadgeCount); // Store the precomputed value in state
    }
  }, [children, indicators]);

  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle),
          }}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
          <ListItemText disableTypography primary={t(`pagesSection.${title}`)} />
          {info && info}
          {badgeCount > 0 && <Badge color="primary" badgeContent={badgeCount} sx={{ mr: '16px' }} />}
          <Iconify
            icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
            sx={{ width: 16, height: 16, ml: 1 }}
          />
        </ListItemStyle>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((item) => {
              const { roles, title, path, icon, info } = item;
              const isActiveSub = active(path);

              const indicatorsInfo = indicators && indicators?.find((item) => item.page === title);

              const pagePermissions = _.find(
                userPagePermissions,
                (pagePermissions) => pagePermissions?.page?.name === title
              );

              if (role !== UserRoleEnum.Admin && ((roles && (!role || !roles.includes(role))) || !pagePermissions)) {
                return null;
              }

              return (
                <ListItemStyle
                  key={title}
                  component={RouterLink}
                  to={path}
                  sx={{
                    ml: 4,
                    ...(isActiveSub && activeSubStyle),
                  }}
                >
                  <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
                  <ListItemText disableTypography primary={t(`pages.${title}`)} />
                  {info && info}

                  {indicatorsInfo &&
                    indicatorsInfo.indicators.map((indicator) => (
                      <Badge
                        color={indicatorColors[indicator?.type] || 'primary'}
                        badgeContent={indicator.quantity}
                        key={`badge-${indicatorsInfo?.page}-${indicator?.type}`}
                        style={{ marginRight: '16px' }}
                      />
                    ))}
                </ListItemStyle>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle),
      }}
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      <ListItemText disableTypography primary={t(`pages.${title}`)} />
      {info && info}
    </ListItemStyle>
  );
}

NavSectionV2.propTypes = {
  navConfig: PropTypes.array,
};

export default function NavSectionV2({ navConfig, ...other }) {
  const { pathname } = useLocation();
  const conveyorsMenuList = useSelector(conveyorsMenu.list);

  const match = (path, end = true) => (path ? !!matchPath({ path, end }, pathname) : false);

  const { role, account } = useAuth();

  const { userPagePermissions } = account || {};

  const canSeeConveyorsMenu = useAccess('conveyorTransactions');  

  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {navConfig.map((item) => {
          const { roles, title, children } = item;

          const pageNames = _.chain(children).flatMap('title').compact().uniq().value();

          const availablePages = _.filter(pageNames, (pageName) =>
            userPagePermissions.find((pagePermissions) => pagePermissions?.page?.name === pageName)
          );

          const rolesWithAccess = _.chain(children).flatMap('roles').concat(roles).compact().uniq().value();

          if (
            title !== 'dashboard' &&
            role !== UserRoleEnum.Admin &&
            ((!_.isEmpty(rolesWithAccess) && (!role || !rolesWithAccess.includes(role))) || _.isEmpty(availablePages))
          ) {
            return null;
          }

          return <NavItemV2 key={title} item={item} active={match} />;
        })}
        {canSeeConveyorsMenu && <ConveyorsMenu conveyorsMenuList={conveyorsMenuList} />}
      </List>
    </Box>
  );
}
